import styled from 'styled-components/macro';
import { tablet, phone } from './breakpoints';
import pattern from '../images/stiffy-pattern.png';
import { stiffy0, stiffy1, dinosaur2 } from './colors';
import { ContentWrapper, Heading, Subtitle } from './index';

export const HeroWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 8rem;
`;

export const HeroHeading = styled(Heading)`
  max-width: 70rem;
`;

export const HeroTitle = styled(Subtitle)`
  max-width: 60rem;
`;

export const HeroCourseInfo = styled.ul`
  display: flex;
  width: 70rem;
  padding: 2rem 3rem;
  align-items: center;
  border: 1px solid ${stiffy0};
  justify-content: space-between;

  @media screen and (max-width: ${tablet}px) {
    width: 100%;
    padding: 2rem;
  }

  @media screen and (max-width: ${phone}px) {
    padding: 2rem;
    flex-direction: column;
    align-items: flex-start;

    .hide-mobile {
      display: none;
    }
  }

  li {
    list-style: none;
    margin: 0px 2rem;
    line-height: 2rem;

    @media screen and (max-width: ${tablet}px) {
      margin: 0.5rem;
    }

    span {
      font-family: 'Work Sans', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 1.8rem;
      color: ${dinosaur2};
    }
  }
`;

export const StiffySection = styled.section`
  display: flex;
  padding: 7rem 6rem;
  align-items: center;
  flex-direction: column;
  background-color: ${stiffy0};

  @media screen and (max-width: ${phone}px) {
    padding: 7rem 1rem;
  }
`;

export const PatternWrapper = styled.section`
  background: url(${pattern}), ${stiffy1};
  background-repeat: repeat;
  background-size: 50rem;
  padding: 7rem 6rem;
  overflow: hidden;

  @media screen and (max-width: ${phone}px) {
    padding: 7rem 1rem;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const CurriculumWrapper = styled.div`
  display: flex;
  overflow: visible;
  align-items: center;
`;
