import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import LinkButton from '../../components/link-button';
import CourseCard from '../../components/course-card';
import ContactForm from '../../components/contact-form';
import { Heading, Highlighted, Spacing, Row } from '../../styles';
import {
  FlexRow,
  HeroTitle,
  HeroWrapper,
  StiffySection
} from '../../styles/courses';

const seoOptions = {
  title: 'Software development courses',
  description:
    'Techrex courses offer a foundational or in-depth understanding of building real world websites or mobile applications from scratch.'
};

const AllCourses = ({
  data: {
    allMarkdownRemark: { nodes: courses }
  }
}) => {
  return (
    <Layout seoOptions={seoOptions}>
      <HeroWrapper>
        <Heading align='center'>
          Software development <Highlighted>courses</Highlighted>.
        </Heading>
        <Spacing height={4} />
        <HeroTitle align='center'>
          Our courses offer a foundational or in-depth understanding of building
          real world websites or mobile applications from scratch.
        </HeroTitle>
        <Spacing height={40} />
        <Row>
          <LinkButton
            external
            target='_blank'
            href='https://forms.gle/n8EdJaus41Z5WFrZ6'
          >
            Become a Tutor
          </LinkButton>
        </Row>
      </HeroWrapper>
      <StiffySection>
        <Heading align='center'>Our courses</Heading>
        <Spacing height={20} />
        <FlexRow>
          {courses?.map(({ frontmatter: course }) => (
            <CourseCard key={course.title} {...course} />
          ))}
        </FlexRow>
      </StiffySection>
      <ContactForm />
    </Layout>
  );
};

export default AllCourses;

export const pageQuery = graphql`
  query GetCourses {
    allMarkdownRemark(sort: { order: ASC, fields: frontmatter___priority }) {
      nodes {
        frontmatter {
          comingSoon
          featured
          overview
          path
          popular
          priority
          title
          thumbnail
        }
      }
    }
  }
`;
